<template>
    <div>
        <div class="container">
            <!--*****************************************-->
            <!-- Modal -->
            <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Agreement</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    <div class="modal-body">
                        <p><u>Promo's & Discounts</u></p>
                        <p>
                            Free shipping for purchases over $100.
                            <br />
                            Please check frequently for holiday specials!
                        </p>
                        <p><u>INVOICE & PRODUCTION TIMELINE</u></p>
                        <p>
                            You will recieve a Payment Invoice within 24-48 hours after submitting your order.
                            <br />
                            After Payment Invoice has been completed, your Product will be manufactured within 4-8 weeks.
                            <br />
                            Once your product has been completed you will receive a Shipment Notification with tracking information.
                            <br />
                            Due to Covid please allow for additional manufacturing and shipping times.
                        </p>
                        <p><u>SITE AGREEMENT</u></p>
                        <p>
                            By utilizing this Site you agree to to comply with the following policies below.
                            <br />
                            If you do not agree to these, please do not use this site.
                        </p>
                        <p>
                            <a href="https://facelessmiragestudio.com/privacy-policy.html">Privacy Policy</a> | 
                            <a href="https://facelessmiragestudio.com/cookie-policy.html">Cookie Policy</a> | 
                            <a href="https://facelessmiragestudio.com/terms-of-service.html">Terms of Service</a>
                        </p>
                    </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" data-dismiss="modal" data-toggle="modal" :data-target="nameModal" class="btn btn-primary">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Custom Mug Modal ****************************************************    -->
            <div class="modal fade" id="customMugModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Custom Mug Form</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!-- FORM -->
                            <form id="fs-frm" name="file-upload-form" accept-charset="utf-8" action="https://formspree.io/f/xzbkkday" method="post">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Full Name</span>
                                    </div>
                                    <input name="Name" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required />
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Phone</span>
                                    </div>
                                    <input name="Phone" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required />
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Email</span>
                                    </div>
                                    <input name="Email" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required />
                                </div>
                                <label>Choose Item</label>
                                <div class="form-group">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Type &nbsp; </label>
                                        </div>
                                        <select v-model="Custom_mug_requested_type" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="type">
                                            <option v-for="option in Custom_Mug_Type_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Color &nbsp; </label>
                                        </div>
                                        <select v-model="Custom_mug_requested_color" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="color">
                                            <option v-for="option in Custom_Mug_Color_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Size &nbsp; </label>
                                        </div>
                                        <select v-model="Custom_mug_requested_size" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="size">
                                            <option v-for="option in Custom_Mug_Size_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-default">QUANTITY</span>
                                        </div>
                                        <input type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" name="Quantity" required />
                                    </div>
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Image Link</span>
                                    </div>
                                    <input name="Image Link" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                                </div>
                                <label for="exampleFormControlTextarea1">Item Details and Instructions</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="Details and instructions"></textarea>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-success" @click="ShowDiv()"><i class="fas fa-scroll"></i> Request Quote</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Custom Shirts Modal ****************************************************    -->
            <div class="modal fade" id="customShirtModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Custom Mug Form</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!-- FORM -->
                            <form id="fs-frm" name="file-upload-form" accept-charset="utf-8" action="https://formspree.io/f/xzbkkday" method="post">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Full Name</span>
                                    </div>
                                    <input name="Name" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required />
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Phone</span>
                                    </div>
                                    <input name="Phone" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required />
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Email</span>
                                    </div>
                                    <input name="Email" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required />
                                </div>
                                <label>Choose Item</label>
                                <div class="form-group">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Type &nbsp; </label>
                                        </div>
                                        <select v-model="Custom_shirt_requested_type" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="type">
                                            <option v-for="option in Custom_Shirt_Type_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Color &nbsp; </label>
                                        </div>
                                        <select v-model="Custom_shirt_requested_color" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="color">
                                            <option v-for="option in Custom_Shirt_Color_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Size &nbsp; </label>
                                        </div>
                                        <select v-model="Custom_shirt_requested_size" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="size">
                                            <option v-for="option in Custom_Shirt_Size_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-default">QUANTITY</span>
                                        </div>
                                        <input type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" name="Quantity" required />
                                    </div>
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Image Link</span>
                                    </div>
                                    <input name="Image Link" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
                                </div>
                                <label for="exampleFormControlTextarea1">Item Details and Instructions</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="Details and instructions"></textarea>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-success" @click="ShowDiv()"><i class="fas fa-scroll"></i> Request Quote</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- MUGS Modal ****************************************************    -->
            <div class="modal fade" id="mugsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Cup Form</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!-- FORM -->
                            <form id="fs-frm" name="file-upload-form" accept-charset="utf-8" action="https://formspree.io/f/xzbkkday" method="post">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Full Name</span>
                                    </div>
                                    <input name="Name" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required />
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Phone</span>
                                    </div>
                                    <input name="Phone" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required />
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-default">Email</span>
                                    </div>
                                    <input name="Email" type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required />
                                </div>
                                <label>Choose Item</label>
                                <div class="form-group">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Type &nbsp; </label>
                                        </div>
                                        <select v-model="Mugs_requested_type" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="type">
                                            <option v-for="option in Mugs_type_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Color &nbsp; </label>
                                        </div>
                                        <select v-model="Mugs_requested_color" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="color">
                                            <option v-for="option in Mugs_color_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Size &nbsp; </label>
                                        </div>
                                        <select v-model="Mugs_requested_size" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="size">
                                            <option v-for="option in Mugs_size_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
									<div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">Template &nbsp; </label>
                                        </div>
                                        <select v-model="Mugs_requested_style" style="background-color: black;" class="custom-select form-control form-control2" id="inputGroupSelect01" name="style">
                                            <option v-for="option in Mugs_style_options" :key="option.slug" :value="option.slug">{{ option.name }}</option>
                                        </select>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-default">QUANTITY</span>
                                        </div>
                                        <input type="text" class="form-control form-control2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" name="Quantity" required />
                                    </div>
                                </div>
                                <label for="exampleFormControlTextarea1">Item Details and Instructions</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="Details and instructions"></textarea>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                    <button type="submit" class="btn btn-success" @click="ShowDiv()"><i class="fas fa-scroll"></i> Request Quote</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!--*************************************-->
            <div v-show="alert" class="alert alert-success" style="position: absolute; margin-top: -5vh;"><strong>Item Added To Cart!</strong> press the Shopping Cart to checkout.</div>
            <span style="white-space: nowrap; text-align:center;">
                <h1 class="headline-alert">CUSTOM PRINT SHOP</h1>
            </span>

            <span style="white-space: nowrap; text-align:center;">
                <h2>Custom Requests</h2>
            </span>
            <hr />

            <!-- *************** ITEM BUTTONS *********************** -->
            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ***************CUSTOM MUG REQUEST******************************************* -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>      
                            <img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />
                            <div class="card-body">
                                <h5 class="card-title">Custom Mug Request</h5>
                                <h6 class="card-subtitle mb-2 text-muted">{{ Custom_items }}</h6>
                                <button @click="nameModal = '#customMugModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Request Form</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ***************CUSTOM SHIRT REQUEST******************************************* -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>      
                            <img src="@/assets/media/customshirts.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />
                            <div class="card-body">
                                <h5 class="card-title">Custom Shirt Request</h5>
                                <h6 class="card-subtitle mb-2 text-muted">{{ Custom_items }}</h6>
                                <button @click="nameModal = '#customShirtModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Request Form</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h2>Baby Yoda Collections</h2>
            </span>

            <span style="white-space: nowrap; text-align:center;">
                <h4>Baby Yoda Best Collection</h4>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - BABY YODA BEST********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/vGSExvbFlgE?playlist=vGSExvbFlgE&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Baby, Yoda Best</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h4>Love You I Do Collection</h4>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/1kOdAc-StR0?playlist=1kOdAc-StR0&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Mom</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/IFGk2KysgUI?playlist=IFGk2KysgUI&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Dad</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/QdVhCHYskY8?playlist=QdVhCHYskY8&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Daughter</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/vOiy7s6VvBs?playlist=vOiy7s6VvBs&loop=1"> 
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Son</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/8pLVjfw5sbk?playlist=8pLVjfw5sbk&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Grandma</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/UW2V7lMntW0?playlist=UW2V7lMntW0&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Grandpa</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/y_afbGeTig8?playlist=y_afbGeTig8&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Aunt</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/XjoDuw9safU?playlist=XjoDuw9safU&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Uncle</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/XjmERH1BO4c?playlist=XjmERH1BO4c&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Girlfriend</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Love You I Do********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/VeYoDJ4HlrU?playlist=VeYoDJ4HlrU&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Love You I Do, Boyfriend</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h4>Whey of the Force Collection</h4>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - BABY YODA BEST********************************** -->
                    <!-- ************************MUG - Whey of the Jedi Master********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/6i4Cb4gNQo8?playlist=6i4Cb4gNQo8&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Whey of the Jedi Master</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Whey of the Grey Jedi********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/3NMpZbRX3Io?playlist=3NMpZbRX3Io&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Whey of the Grey Jedi</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Whey of the Sith Lor********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/sAqvUp0A18U?playlist=sAqvUp0A18U&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Whey of the Sith Lord</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h2>Game of Thrones Collections</h2>
            </span>

            <span style="white-space: nowrap; text-align:center;">
                <h4>When Winter's Coming - Lord Collection</h4>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - GoT Banner Lord********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/LRXNGNXGp04?playlist=LRXNGNXGp04&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lord of Arryn</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lord********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/w-nrqEXKtA4?playlist=w-nrqEXKtA4&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lord of Baratheon</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lord********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/0CdYNHOY8xU?playlist=0CdYNHOY8xU&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lord of Greyjoy</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lord********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/5Khy5_YCdrU?playlist=5Khy5_YCdrU&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lord of Lannister</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lord********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/mhM7454o2CA?playlist=mhM7454o2CA&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lord of Martell</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lord********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/OVA4VOCR850?playlist=OVA4VOCR850&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lord of Stark</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lord********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/SwcWEIFz1FA?playlist=SwcWEIFz1FA&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lord of Targaryen</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lord********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/iUwUPwd9q4U?playlist=iUwUPwd9q4U&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lord of Tully</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lord********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/Y7ZBzNcZcU8?playlist=Y7ZBzNcZcU8&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lord of Tyrell</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h4>When Winter's Coming - Lady Collection</h4>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - GoT Banner Lady********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/rYkJjSSVvQM?playlist=rYkJjSSVvQM&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lady of Arryn</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lady********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/3O8FLlJiBps?playlist=3O8FLlJiBps&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lady of Baratheon</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lady********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/X3qJpb2glc0?playlist=X3qJpb2glc0&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lady of Greyjoy</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lady********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/3j6T7Qfc2uw?playlist=3j6T7Qfc2uw&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lady of Lannister</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lady********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/hM8vq2e7BC0?playlist=hM8vq2e7BC0&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lady of Martell</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lady********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/vIN5FPuTShk?playlist=vIN5FPuTShk&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lady of Stark</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lady********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/Idh1K45PTlU?playlist=Idh1K45PTlU&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lady of Targaryen</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lady********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/ABtCJP6dIdw?playlist=ABtCJP6dIdw&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lady of Tully</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lady********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/RQk7nEHBA50?playlist=RQk7nEHBA50&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lady of Tyrell</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h4>When Winter's Coming - Lad Collection</h4>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - GoT Banner Lad********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/zyWtjlMJF9Q?playlist=zyWtjlMJF9Q&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lad of Arryn</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lad********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/WJkciuvYfKs?playlist=WJkciuvYfKs&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lad of Baratheon</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lad********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/Hkqjcm22Nlc?playlist=Hkqjcm22Nlc&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lad of Greyjoy</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lad********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/7tonrYaMWRk?playlist=7tonrYaMWRk&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lad of Lannister</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lad********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/SeaAw0JHEVA?playlist=SeaAw0JHEVA&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lad of Martell</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lad********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/oCZC5-D0hPE?playlist=oCZC5-D0hPE&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lad of Stark</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lad********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/ug-HlqSnG98?playlist=ug-HlqSnG98&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lad of Targaryen</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lad********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/sqDE9rBZSFQ?playlist=sqDE9rBZSFQ&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lad of Tully</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - GoT Banner Lad********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/vIGmBn-Lqeg?playlist=vIGmBn-Lqeg&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/stark.png" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Lad of Tyrell</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h2>Military Collection</h2>
            </span>

            <span style="white-space: nowrap; text-align:center;">
                <h4>Enlisted Ranks</h4>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - USAF Enlisted********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/ul1NfLRjbb8?playlist=ul1NfLRjbb8&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF E-2</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Enlisted********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/qP2qOPROvV4?playlist=qP2qOPROvV4&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF E-3</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Enlisted********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/OakuqyKJJ-k?playlist=OakuqyKJJ-k&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF E-4</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Enlisted********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/1c1xWmggIlA?playlist=1c1xWmggIlA&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF E-5</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Enlisted********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/wPACgnJwkig?playlist=wPACgnJwkig&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF E-6</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Enlisted********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/rezlG9-mVnY?playlist=rezlG9-mVnY&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF E-7</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Enlisted********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/Kr7ao61RTjo?playlist=Kr7ao61RTjo&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF E-8</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Enlisted********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/IiD5LuN2wUE?playlist=IiD5LuN2wUE&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF E-9</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h4>Officer Ranks</h4>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/XYwNYuFK1jw?playlist=XYwNYuFK1jw&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-1</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/omCJfOngDNc?playlist=omCJfOngDNc&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-2</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/_77AzhUzLqU?playlist=_77AzhUzLqU&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-3</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/8TP5Hgshg_E?playlist=8TP5Hgshg_E&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-4</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/9kMU675iLy8?playlist=9kMU675iLy8&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-5</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/xbU23hXrzPs?playlist=xbU23hXrzPs&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-6</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/5fVzHDr_N74?playlist=5fVzHDr_N74&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-7</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/YSbeYgBIykM?playlist=YSbeYgBIykM&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-8</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/BEe2rW_kA4E?playlist=BEe2rW_kA4E&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-9</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - USAF Officer********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/cJ6okj0V4xM?playlist=cJ6okj0V4xM&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">USAF O-10</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h3>Wedding Collection</h3>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - Maid of Honor********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/LP98VSZKFxc?playlist=LP98VSZKFxc&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Maid of Honor</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!--<h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ************************MUG - Maid of Honor********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/Ew7zZ3AtZM0?playlist=Ew7zZ3AtZM0&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Bridesmaid</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!--x42QfUFwdp0 <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            <br /><hr />
            <span style="white-space: nowrap; text-align:center;">
                <h3>Fun/Test Designs</h3>
            </span>
            <hr /><br />

            <div class="container">
                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <!-- ************************MUG - Running Late Cardio********************************** -->
                    <div class="col mb-3">
                        <!--mb-3: Fix for card height 100% issue with margin-->
                        <div class="card h-100 mb-0">
                            <!--mb-0: Fix for card height 100% issue with margin-->
                            <span class="corner-border-top"></span>
                            <iframe height="300"
                            src="https://www.youtube.com/embed/ZDjo8q9mpgE?playlist=ZDjo8q9mpgE&loop=1">
                            </iframe>
                            <!--<img src="@/assets/media/custommugs.jpg" height="300px;" class="card-img-top" alt="Carina Nebula captured by the Hubble Space Telescope" />-->
                            <div class="card-body">
                                <h5 class="card-title">Running Late is my Cardio</h5>
                                <h6 class="card-subtitle mb-2 text-muted">${{ Mug_price }}</h6>
                                <!-- <h6 class="card-subtitle mb-2 text-muted">Quantity:{{ mugs }}</h6> -->
                                <!--<a class="btn btn-primary" @click="ShowDiv(), setMugCount(mugs + 1)"><i class="fa fa-fw fa-shopping-cart"></i> Add to cart</a>-->
                                <button @click="nameModal = '#mugsModal'" type="button" class="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#exampleModalLong"><i class="fas fa-scroll"></i> Submit Order</button>
                            </div>
                            <span class="corner-border-bottom"></span>
                        </div>
                    </div>
                    <!-- ********************************************************** -->
                    <div style="padding-left: 2vh; padding-right: 2vh;">
                        <!-- <router-link style="text-decoration: none;" to="/cart">
                            <button class="btn btn-lg btn-block btn-success"><i class="fa fa-fw fa-coins"></i> Go to Checkout</button>
                        </router-link>-->
                    </div>
                    <div style="margin-bottom: 10vh;"></div>
                </div>
            </div>
            <br />
        </div>
    </div>
</template>

<script>
    import { store, mutations } from "../store";
    export default {
        computed: {
            mugs() {
                return store.mugs;
            },
            shirts() {
                return store.shirts;
            },
        },
        data() {
            return {
                alert: false,
                nameModal: "#customModal",
                checkedNames: [],
                Custom_items: "Get Quote ",
                Shirt_price: "15 EACH ",
                Mug_price: "15 EACH ",
                Mug_quantity: 0,
                Shirt_quantity: 0,
                Request_option: "",
                Request_quantity: 0,

                // CUSTOM MUG VARIABLES
                Custom_mug_requested_type: "",
                Custom_Mug_Type_options: [
                    {
                        slug: "Mugs - Heat Changing",
                        name: "Mugs - Heat Changing",
                    },
                    {
                        slug: "Mugs - Interior Colored",
                        name: "Mugs - Interior Colored",
                    },
                ],
                Custom_mug_requested_color: "",
                Custom_Mug_Color_options: [
                    {
                        slug: "Black - Heat Changing",
                        name: "Black - Heat Changing",
                    },
                    {
                        slug: "Black",
                        name: "Black",
                    },
                    {
                        slug: "Blue",
                        name: "Blue",
                    },
                    {
                        slug: "Green",
                        name: "Green",
                    },
                    {
                        slug: "Red",
                        name: "Red",
                    },
                    {
                        slug: "White",
                        name: "White",
                    },
                ],
                Custom_mug_requested_size: "",
                Custom_Mug_Size_options: [
                    {
                        slug: "11oz - Heat Changing",
                        name: "11oz - Heat Changing",
                    },
                    {
                        slug: "11oz",
                        name: "11oz",
                    },
                    {
                        slug: "15oz",
                        name: "15oz",
                    },
                ],

                // CUSTOM Shirt VARIABLES
                Custom_shirt_requested_type: "",
                Custom_Shirt_Type_options: [
                    {
                        slug: "Polyester",
                        name: "Polyester",
                    },
                ],
                Custom_shirt_requested_color: "",
                Custom_Shirt_Color_options: [
                    {
                        slug: "White",
                        name: "White",
                    },
                ],
                Custom_shirt_requested_size: "",
                Custom_Shirt_Size_options: [
                    {
                        slug: "Small",
                        name: "Small",
                    },
                    {
                        slug: "Medium",
                        name: "Medium",
                    },
                    {
                        slug: "Large",
                        name: "Large",
                    },
                    {
                        slug: "X-Large",
                        name: "X-Large",
                    },
                ],

                // MUGS VARIABLE
                Mugs_requested_type: "",
                Mugs_type_options: [
                    {
                        slug: "Mug",
                        name: "Mug",
                    },
                ],
                Mugs_requested_color: "",
                Mugs_color_options: [
                    {
                        slug: "Black - Heat Changing",
                        name: "Black - Heat Changing",
                    },
                ],
                Mugs_requested_size: "",
                Mugs_size_options: [
                    {
                        slug: "11oz",
                        name: "11oz",
                    },
                ],
                Mugs_requested_style: "",
                Mugs_style_options: [
                    {
                        slug: "Love You I Do",
                        name: "Love You I Do",
                    },
                    {
                        slug: "Baby, Yoda Best",
                        name: "Baby, Yoda Best",
                    },
                    {
                        slug: "Whey of the Jedi Master",
                        name: "Whey of the Jedi Master",
                    },
                    {
                        slug: "Whey of the Grey Jedi",
                        name: "Whey of the Grey Jedi",
                    },
                    {
                        slug: "Whey of the Sith Lord",
                        name: "Whey of the Sith Lord",
                    },
                    {
                        slug: "GoT Banner Lad",
                        name: "GoT Banner Lady",
                    },
                    {
                        slug: "GoT Banner Lady",
                        name: "GoT Banner Lady",
                    },
                    {
                        slug: "GoT Banner Lord",
                        name: "GoT Banner Lord",
                    },
                    {
                        slug: "USAF Enlisted Rank",
                        name: "USAF Enlisted Rank",
                    },
                    {
                        slug: "USAF Officer Rank",
                        name: "USAF Officer Rank",
                    },
                    {
                        slug: "Maid of Honor",
                        name: "Maid of Honor",
                    },
                    {
                        slug: "Bridesmaid",
                        name: "Bridesmaid",
                    },
                    {
                        slug: "Running Late Cardio",
                        name: "Running Late Cardio",
                    },
                ],
            };
        },
        created() {},
        methods: {
            setMugCount: mutations.setMugCount,
            setShirtCount: mutations.setShirtCount,
            cancelDiv: function () {
                this.alert = false;
            },
            ShowDiv: function () {
                this.alert = true;
                setTimeout(() => (this.alert = false), 3000);
            },
        },
        name: "shop",
        components: {},
    };
</script>

<style scoped>
    .form-control2 {
        height: 50px;
    }
</style>
